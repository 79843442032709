// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { StyledButton } from 'admin/common/components/Button/Styles';
import { ButtonProps as UIButtonProps } from '@plesk/ui-library';
import {
    Link,
    LinkProps,
} from 'react-router-dom';
import React from 'react';

type BaseButtonProps = Omit<UIButtonProps, 'component'> & {
    isLoading?: boolean;
};

type LinkButtonProps = BaseButtonProps & {
    to: LinkProps['to'];
} & LinkProps;

type RegularButtonProps = BaseButtonProps & {
    to?: undefined;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonProps = LinkButtonProps | RegularButtonProps;

export function Button({
    isLoading,
    state,
    to,
    ...rest
}: ButtonProps) {
    const props = {
        ...rest,
        state: isLoading ? 'loading' : state || null,
    };

    return to
        ? <StyledButton component={Link} to={to} {...props} />
        : <StyledButton {...props} />;
}
