// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import IpBlockForm, { FIELDS } from 'admin/ipBlock/containers/IpBlockForm';
import {
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import { pathTo } from 'common/helpers/core';
import { Link } from 'react-router-dom';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export interface IOnboardingStepAddIpBlockProps {
    onNext: () => void;
}

export type OnboardingStepAddIpBlockProps =
    IOnboardingStepAddIpBlockProps
    & ReturnType<typeof mapStateToProps>;

export const OnboardingStepAddIpBlock: React.FC<OnboardingStepAddIpBlockProps> = ({
    onNext,
    isItemSaving,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    return (
        <OnboardingStep
            sidebar={(<OnboardingStepper/>)}
        >
            <OnboardingHeader>
                <Translate content={'onboarding.clusterSetup.steps.addIpBlock.header'}/>
            </OnboardingHeader>
            <OnboardingLead>
                <Translate content={'onboarding.clusterSetup.steps.addIpBlock.lead'}/>
            </OnboardingLead>
            <p>
                <Translate content={'onboarding.clusterSetup.steps.addIpBlock.paragraphs.0'}/>
            </p>
            <IpBlockForm
                ref={formRef}
                onSubmit={onNext}
                fields={[
                    FIELDS.NAME,
                    FIELDS.LIST_TYPE,
                    FIELDS.TYPE,
                    FIELDS.IP_RANGE_FROM,
                    FIELDS.IP_RANGE_TO,
                    FIELDS.IP_RANGE,
                    FIELDS.SUBNET,
                    FIELDS.GATEWAY,
                    FIELDS.NETMASK,
                    FIELDS.NS_1,
                    FIELDS.NS_2,
                    FIELDS.COMPUTE_RESOURCES,
                ]}
            />
            <p>
                <Translate
                    content={'onboarding.clusterSetup.steps.addIpBlock.hints.advancedConfiguration'}
                    params={{
                        ipBlocksSettingsLink: (
                            <Link
                                to={pathTo('ip_blocks')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Translate content={'onboarding.clusterSetup.steps.addIpBlock.hints.ipBlocksSettingsLinkTitle'} />
                            </Link>
                        ),
                    }}
                />
            </p>
            <OnboardingContentFooter>
                <Button
                    size={SIZE.LG}
                    intent={'secondary'}
                    onClick={close}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addIpBlock.buttons.close'}/>
                </Button>
                <Button
                    size={SIZE.LG}
                    intent={'primary'}
                    onClick={() => formRef.current?.submit()}
                    isLoading={isItemSaving}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addIpBlock.buttons.continue'}/>
                </Button>
            </OnboardingContentFooter>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_IPBLOCK_ITEM),
});

export default connect(mapStateToProps)(OnboardingStepAddIpBlock);