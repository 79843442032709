// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import ComputeResourceForm, { FIELDS } from 'admin/computeResource/list/containers/ComputeResourceForm/ComputeResourceForm';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';

export interface IOnboardingStepAddComputeResourceProps {
    onNext: (computeResourceId: number) => void;
    item: IComputeResourceResponse;
}

export type OnboardingStepAddComputeResourceProps =
    IOnboardingStepAddComputeResourceProps
    & ReturnType<typeof mapStateToProps>;

export const OnboardingStepAddComputeResource: React.FC<OnboardingStepAddComputeResourceProps> = ({
    onNext,
    item,
    isLoading,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    return (
        <OnboardingStep sidebar={(<OnboardingStepper/>)} >
            <>
                <OnboardingHeader>
                    <Translate content={'onboarding.clusterSetup.steps.addComputeResource.header'}/>
                </OnboardingHeader>
                <OnboardingLead>
                    <Translate content={'onboarding.clusterSetup.steps.addComputeResource.lead'}/>
                </OnboardingLead>
                <p>
                    <Translate content={'onboarding.clusterSetup.steps.addComputeResource.paragraphs.0'}/>
                </p>
                <ComputeResourceForm
                    ref={formRef}
                    item={item}
                    fields={[
                        FIELDS.NAME,
                        FIELDS.HOST,
                        FIELDS.AGENT_PORT,
                        FIELDS.LOGIN,
                        FIELDS.PORT,
                        FIELDS.TYPE,
                        FIELDS.LICENSE_TYPE,
                    ]}
                    onSubmit={onNext}
                />
                <p>
                    <Translate
                        content={'onboarding.clusterSetup.steps.addComputeResource.hints.advancedConfiguration'}
                        params={{
                            computeResourcesLink: (
                                <Link
                                    to={pathTo('compute_resources')}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content={'onboarding.clusterSetup.steps.addComputeResource.hints.computeResourcesLinkTitle'} />
                                </Link>
                            ),
                        }}
                    />
                </p>
                <OnboardingContentFooter>
                    <Button
                        size={SIZE.LG}
                        intent={'secondary'}
                        onClick={close}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.addComputeResource.buttons.close'}/>
                    </Button>
                    <Button
                        size={SIZE.LG}
                        intent={'primary'}
                        onClick={() => formRef.current?.submit()}
                        isLoading={isLoading}
                    >
                        <Translate content={'onboarding.clusterSetup.steps.addComputeResource.buttons.continue'}/>
                    </Button>
                </OnboardingContentFooter>
            </>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_ITEM)
        || state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE),
});

export default connect(mapStateToProps)(OnboardingStepAddComputeResource);
