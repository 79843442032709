// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as licenseActions from 'admin/license/solusVM/actions';
import * as responseErrorActions from 'common/modules/app/responseError/actions';

import { IActivateSolusVMLicenseRequest } from 'common/api/resources/License/solusVM';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';

export enum LicenseActivationFormFields {
    ACTIVATION_CODE = 'activation_code',
    SUBMIT = 'submit',
}

interface ILicenseActivationFormProps {
    onSubmit: () => void;
    fields?: LicenseActivationFormFields[];
}

export type LicenseActivationFormProps =
    ILicenseActivationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LicenseActivationForm = React.forwardRef<FormInstanceHandles, LicenseActivationFormProps>(({
    isActivating,
    errors,
    responseError,
    formErrorsActions: { setFormErrors, clearFormErrors },
    responseErrorActions: { clearResponseError },
    licenseActions: { activateLicense },
    onSubmit,
    fields = [
        LicenseActivationFormFields.ACTIVATION_CODE,
        LicenseActivationFormFields.SUBMIT,
    ],
}, ref) => {
    React.useEffect(() => {
        clearFormErrors();
        clearResponseError();
    }, [clearFormErrors, clearResponseError]);

    const handleSubmit = async (values: IActivateSolusVMLicenseRequest) => {
        clearResponseError();

        const validationErrors = validate<IActivateSolusVMLicenseRequest>(values, {
            activation_code: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await activateLicense(values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    return (
        <>
            <Form
                ref={ref}
                id="licenseForm"
                onSubmit={handleSubmit}
                errors={errors}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                {fields.includes(LicenseActivationFormFields.ACTIVATION_CODE) && (
                    <FormFieldText
                        name="activation_code"
                        size="fill"
                        label={<Translate content="license.form.activationCode" />}
                        required={true}
                    />
                )}
                {fields.includes(LicenseActivationFormFields.SUBMIT) && (
                    <Button
                        type="submit"
                        form="licenseForm"
                        fill={true}
                        intent={INTENT_TYPE.PRIMARY}
                        size="lg"
                        isLoading={isActivating}
                    >
                        <Translate content="license.form.activateBtn" />
                    </Button>
                )}
                {responseError && (<FormErrors errors={[responseError]} />)}
            </Form>
        </>
    );
});

const mapStateToProps = (state: RootState) => ({
    isActivating: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ACTIVATION),
    errors: state.app.formErrors,
    responseError: state.app.responseError.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(LicenseActivationForm);
