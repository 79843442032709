// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { api } from 'common/api/resources/Response';
import {
    IOnboarding,
    OnboardingStep,
} from 'common/api/resources/Onboarding';

export const onboarding = {
    get: () => api.get<IOnboarding>('/onboarding/cluster-setup'),
    update: (step: OnboardingStep, compute_resource_id?: number) => api.post('/onboarding/cluster-setup', {
        step,
        compute_resource_id,
    }),
};
