// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    validate,
    requiredRule,
    numericRule,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as computeResourceActions from 'admin/computeResource/actions';
import {
    Button,
    Column,
    Columns,
    copyToClipboard,
    Form,
    FormField,
    FormFieldText,
    FormFieldSelect,
    Input,
    Section,
    Translate,
    SelectOption,
    FormInstanceHandles,
} from '@plesk/ui-library';
import {
    isComputeResourceSelected,
    getComputeResourceInstallSteps,
} from 'admin/computeResource/selectors';
import {
    AuthTypeContainer,
    OneTimeKeyPairCountdown,
    OneTimeKeyPairGenerating,
    TypeContainer,
} from 'admin/computeResource/list/containers/ComputeResourceForm/Styles';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    ISelectOption,
    ProgressStates,
} from 'common/components';
import {
    IComputeResourceCreateRequest,
    IComputeResourceResponse,
    LicenseType,
} from 'common/api/resources/ComputeResource';
import { COMPUTE_RESOURCE_FORM } from 'admin/computeResource/constants/tests';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    createOptionsLoader,
    createChangeHandler,
} from 'common/components/Select/helpers';
import { FixedMultilineFormText } from 'common/components/styles/FixedMultilineFromText';
import SSHGenerateButton from 'common/components/SSHGenerateButton/SSHGenerateButton';
import {
    ILocationResponse,
    locations,
} from 'common/api/resources/Location';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    IIpBlockResponse,
    ipBlocks,
} from 'common/api/resources/IpBlock';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    generateSSHKeypair,
    ISSHKeypair,
} from 'common/services/SSHKeyGenerator';
import * as toasterActions from 'common/modules/app/toaster/actions';
import { COMPUTE_RESOURCE_AUTH_TYPE } from 'admin/computeResource/constants';
import * as keypairCache from 'admin/computeResource/services/cache';
import moment,
{ Moment } from 'moment';
import Countdown from 'common/components/Countdown/Countdown';
import * as Sentry from '@sentry/browser';
import { LicenseModel } from 'common/api/resources/Settings';
import { upFirstChar } from 'common/helpers/string';
import { SSHGenerateButtonContainer } from 'common/components/SSHGenerateButton/Styles';

interface IComputeResourceFormProps {
    item: IComputeResourceResponse;
    fields?: FIELDS[];
    onSubmit?: (id: number) => void;
}

export type ComputeResourceFormProps =
    IComputeResourceFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

enum AUTH_TYPE {
    ONETIME_SSHKEY = 'onetime_sshkey',
    SSHKEY = 'sshkey',
    PASSWORD = 'password',
}

interface IComputeResourceCreateData extends IComputeResourceCreateRequest {
    onetime_key: string;
    onetime_date: number;
}

const dataToRequest = (d: IComputeResourceCreateData): IComputeResourceCreateRequest => {
    const r: IComputeResourceCreateRequest = {
        name: d.name,
        host: d.host,
        type: COMPUTE_RESOURCE_AUTH_TYPE.LKEY,
        login: d.login,
        port: d.port,
        key: d.key,
        password: d.password,
        agent_port: d.agent_port,
        locations: d.locations,
        ip_blocks: d.ip_blocks,
        license_type: d.license_type,
    };

    switch (d.type) {
    case AUTH_TYPE.ONETIME_SSHKEY:
        r.key = d.onetime_key;
        break;

    case AUTH_TYPE.SSHKEY:
        r.key = d.key;
        break;

    case AUTH_TYPE.PASSWORD:
        r.type = COMPUTE_RESOURCE_AUTH_TYPE.LPASS;
        break;
    }

    return r;
};

const defaultValues: IComputeResourceCreateData = Object.freeze({
    host: '',
    name: '',
    login: 'root',
    onetime_key: '',
    onetime_date: 0,
    key: '',
    password: '',
    type: AUTH_TYPE.ONETIME_SSHKEY,
    port: '22',
    agent_port: '8080',
    locations: [],
    ip_blocks: [],
    license_type: LicenseType.STANDARD,
});

const authTypes = [
    {
        value: AUTH_TYPE.ONETIME_SSHKEY,
        label: <Translate content="computeResource.actionDialog.oneTimePair.type" />,
    },
    {
        value: AUTH_TYPE.SSHKEY,
        label: <Translate content="computeResource.actionDialog.sshKey" />,
    },
    {
        value: AUTH_TYPE.PASSWORD,
        label: <Translate content="computeResource.actionDialog.password" />,
    },
];

const locationToSelectOptions = (location: ILocationResponse) => ({
    label: location.name,
    value: location.id.toString(),
});

const ipBlockToSelectOptions = (ipBlock: IIpBlockResponse) => ({
    label: ipBlock.name,
    value: ipBlock.id.toString(),
});

const computeOneTimeKeypairExpirationDate = (): Moment => moment().add(1, 'hour');

const generateOneTimeKeypair = async (id: number, userEmail: string): Promise<keypairCache.IExpirableSSHKeypair> => {
    const item = keypairCache.get(id);
    if (item !== null) {
        return item;
    }

    const pair = await generateSSHKeypair(userEmail);
    const expiresAt = computeOneTimeKeypairExpirationDate();
    keypairCache.set(id, pair, expiresAt);
    return {
        ...pair,
        expiresAt,
    };
};

export enum FIELDS {
    NAME = 'name',
    HOST = 'host',
    AGENT_PORT = 'agent_port',
    LOGIN = 'login',
    PORT = 'port',
    TYPE = 'type',
    LOCATIONS = 'locations',
    IP_BLOCKS = 'ip_blocks',
    LICENSE_TYPE = 'license_type',
}

export const ComputeResourceForm = React.forwardRef<FormInstanceHandles, ComputeResourceFormProps>(({
    userEmail,
    item,
    isItemSelected,
    installSteps,
    licenseModel,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    computeResourceActions: {
        createComputeResource,
        retryInstallationComputeResource,
        setInstallSteps,
    },
    bakeToast,
    fields,
    onSubmit,
}, ref) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });
    const [selectedLocations, setSelectedLocations] = React.useState<ISelectOption[]>([]);
    const [selectedIpBlocks, setSelectedIpBlocks] = React.useState<ISelectOption[]>([]);
    const [oneTimeKeyPair, setOneTimeKeyPair] = React.useState<ISSHKeypair|null>(null);
    const [oneTimeKeyPairExpiresAt, setOneTimeKeyPairExpiresAt] = React.useState<Moment>(moment());
    const [isOneTimeKeyPairGenerating, setOneTimeKeyPairGenerating] = React.useState<boolean>(false);
    const noFieldsFilter = fields === undefined;

    const getOneTimeKeypair = async () => {
        setOneTimeKeyPairGenerating(true);

        try {
            const keyPair = await generateOneTimeKeypair(item.id, userEmail);

            setSubmitValues(prevState => ({
                ...prevState,
                onetime_key: keyPair.private,
            }));
            setOneTimeKeyPair(keyPair);
            setOneTimeKeyPairExpiresAt(keyPair.expiresAt);
        } catch (e) {
            Sentry.captureException(e);
            bakeToast(INTENT_TYPE.DANGER, 'computeResource.actionDialog.oneTimePair.failedToGenerate');
            setSubmitValues(prevState => ({
                ...prevState,
                type: AUTH_TYPE.SSHKEY,
            }));
        } finally {
            setOneTimeKeyPairGenerating(false);
        }
    };

    React.useEffect(() => {
        setSubmitValues((prevState) => ({
            ...prevState,
            locations: item.locations.map((location) => location.id),
        }));

        if (isItemSelected) {
            setSubmitValues(prevState => ({
                ...prevState,
                host: item.host,
                name: item.name,
                agent_port: item.agent_port.toString(),
                locations: item.locations.map((location) => location.id),
                ip_blocks: item.ip_blocks ? item.ip_blocks!.map((ipBlock) => ipBlock.id) : [],
            }));
            setSelectedLocations(item.locations.map(locationToSelectOptions));
            setSelectedIpBlocks(item.ip_blocks ? item.ip_blocks.map(ipBlockToSelectOptions) : []);
        }

        getOneTimeKeypair();

        return () => {
            clearFormErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLocationsChange = createChangeHandler(
        setSubmitValues,
        setSelectedLocations,
        'locations'
    );

    const handleIpBlocksChange = createChangeHandler(
        setSubmitValues,
        setSelectedIpBlocks,
        'ip_blocks'
    );

    const loadLocationOptions = createOptionsLoader(
        locations.list,
        locationToSelectOptions
    );

    const loadIpBlockOptions = createOptionsLoader(
        ipBlocks.list,
        ipBlockToSelectOptions
    );

    const handleCreate = async (data: IComputeResourceCreateData) => {
        const values = dataToRequest(data);

        const errors = validate<IComputeResourceCreateRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            login: requiredRule(<Translate content="validate.fieldRequired" />),
            host: requiredRule(<Translate content="validate.fieldRequired" />),
            port: numericRule(<Translate content="validate.fieldRequired" />),
            agent_port: numericRule(<Translate content="validate.fieldRequired" />),
            [values.type === COMPUTE_RESOURCE_AUTH_TYPE.LPASS
                ? 'password'
                : 'key'
            ]: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            let computeResourceId: number;
            if (isItemSelected) {
                setInstallSteps(item.id, []);
                await retryInstallationComputeResource(item.id, values);

                computeResourceId = item.id;
            } else {
                const result = await createComputeResource(values);
                if (result?.data?.data?.id) {
                    keypairCache.move(0, result.data.data.id);
                }

                computeResourceId = result.data.data.id;
            }

            onSubmit?.(computeResourceId);
        } catch (e) {
            throw e;
        }
    };

    const handleSSHKeyGenerateSuccess = (_: string, key: string) => {
        setSubmitValues((values) => ({
            ...values,
            key,
        }));
    };

    const handleNameChange = (value: string) => {
        setSubmitValues({ ...submitValues, name: value });
    };

    const handleHostChange = (value: string) => {
        setSubmitValues({ ...submitValues, host: value });
    };

    const handleAgentPortChange = (value: string) => {
        setSubmitValues({ ...submitValues, agent_port: value });
    };

    const handleLoginChange = (value: string) => {
        setSubmitValues({ ...submitValues, login: value });
    };

    const handlePortChange = (value: string) => {
        setSubmitValues({ ...submitValues, port: value });
    };

    const handleAuthTypeChange = (value: string) => {
        setSubmitValues(prevState => ({ ...prevState, type: value }));
    };

    const handlePasswordChange = (value: string) => {
        setSubmitValues({ ...submitValues, password: value });
    };

    const handleSshKeyChange = (value: string) => {
        setSubmitValues({ ...submitValues, key: value });
    };

    const handleLicenseTypeChange = (value: string) => {
        setSubmitValues({ ...submitValues, license_type: value as LicenseType });
    };

    const installStepsEl = installSteps
        .map(({ id, progress, status_text, ...step }) => ({
            key: id.toString(),
            progress: -1,
            statusText: status_text,
            ...step,
        }));

    const oneTimeOneLineScript = `sudo -u root -- sh -xc 'mkdir -p /root/.ssh/ && echo "${oneTimeKeyPair?.public}" >> /root/.ssh/authorized_keys && chmod 600 /root/.ssh/authorized_keys && cat /root/.ssh/authorized_keys'`;

    const copy = () => {
        copyToClipboard(oneTimeOneLineScript);
        bakeToast(INTENT_TYPE.SUCCESS, 'copyText.copied');
    };

    const canRenderField = (requiredField: FIELDS) => noFieldsFilter || fields.includes(requiredField);

    const renderAuthInputs = () => {
        switch (submitValues.type) {
        case AUTH_TYPE.ONETIME_SSHKEY:
            if (isOneTimeKeyPairGenerating) {
                return (
                    <OneTimeKeyPairGenerating progress={true}>
                        <Translate content="computeResource.actionDialog.oneTimePair.generating"/>
                    </OneTimeKeyPairGenerating>
                );
            }

            return (
                <>
                    <Translate content="computeResource.actionDialog.oneTimePair.help"/>
                    <div>
                        <Translate content="computeResource.actionDialog.oneTimePair.validTo"/>
                        <OneTimeKeyPairCountdown>
                            <Countdown
                                to={oneTimeKeyPairExpiresAt}
                                onFinish={getOneTimeKeypair}
                            />
                        </OneTimeKeyPairCountdown>
                    </div>
                    <Input
                        value={oneTimeOneLineScript}
                        size={SIZE.FILL}
                        disabled={true}
                        suffix={
                            <Button
                                icon={ICONS.COPY}
                                ghost={true}
                                onClick={copy}
                            />
                        }
                    />
                </>
            );

        case AUTH_TYPE.SSHKEY:
            return (
                <>
                    <FixedMultilineFormText height="200px">
                        <FormFieldText
                            data-cy={COMPUTE_RESOURCE_FORM.KEY}
                            size={SIZE.FILL}
                            key="sshKey"
                            name="key"
                            multiline={true}
                            onChange={handleSshKeyChange}
                        />
                    </FixedMultilineFormText>
                    <SSHGenerateButtonContainer>
                        <SSHGenerateButton
                            tooltip={<Translate content="computeResource.actionDialog.sshGenerate.tooltip"/>}
                            email={userEmail}
                            onSuccessfulGenerate={handleSSHKeyGenerateSuccess}
                        >
                            <Translate content="computeResource.actionDialog.sshGenerate.title"/>
                        </SSHGenerateButton>
                    </SSHGenerateButtonContainer>
                </>
            );

        case AUTH_TYPE.PASSWORD:
            return (
                <FormFieldPassword
                    data-cy={COMPUTE_RESOURCE_FORM.PASSWORD}
                    name="password"
                    size={SIZE.FILL}
                    description={<Translate content="computeResource.actionDialog.passwordDescription"/>}
                    onChange={handlePasswordChange}
                />
            );
        }

        return null;
    };

    return (
        <>
            <Form
                ref={ref}
                id="computeResourceForm"
                data-cy={COMPUTE_RESOURCE_FORM.FORM}
                footerClassName="hidden"
                onSubmit={handleCreate}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    {canRenderField(FIELDS.NAME) && (
                        <FormFieldText
                            size={SIZE.FILL}
                            name="name"
                            label={<Translate content="computeResource.actionDialog.name" />}
                            required={true}
                            onChange={handleNameChange}
                        />
                    )}
                    {(canRenderField(FIELDS.HOST) || canRenderField(FIELDS.AGENT_PORT)) && (
                        <Columns gap={SIZE.LG} vertical={false}>
                            {(canRenderField(FIELDS.HOST)) && (
                                <Column fill={true}>
                                    <FormFieldText
                                        size={SIZE.FILL}
                                        name="host"
                                        description={<Translate content="computeResource.actionDialog.hostDescription" />}
                                        label={<Translate content="computeResource.actionDialog.host" />}
                                        required={true}
                                        onChange={handleHostChange}
                                        vertical
                                    />
                                </Column>
                            )}
                            {(canRenderField(FIELDS.AGENT_PORT)) && (
                                <Column width={85}>
                                    <FormFieldText
                                        name="agent_port"
                                        size={SIZE.FILL}
                                        label={<Translate content="computeResource.actionDialog.agentPort" />}
                                        required={true}
                                        onChange={handleAgentPortChange}
                                        vertical
                                    />
                                </Column>
                            )}
                        </Columns>
                    )}
                    {(canRenderField(FIELDS.LOGIN) || canRenderField(FIELDS.PORT)) && (
                        <Columns gap={SIZE.LG} vertical={false}>
                            {(canRenderField(FIELDS.LOGIN)) && (
                                <Column fill={true}>
                                    <FormFieldText
                                        name="login"
                                        size={SIZE.FILL}
                                        label={<Translate content="computeResource.actionDialog.login" />}
                                        disabled={true}
                                        required={true}
                                        onChange={handleLoginChange}
                                        vertical
                                    />
                                </Column>
                            )}
                            {(canRenderField(FIELDS.PORT)) && (
                                <Column width={85}>
                                    <FormFieldText
                                        name="port"
                                        size={SIZE.FILL}
                                        label={<Translate content="computeResource.actionDialog.sshPort" />}
                                        required={true}
                                        onChange={handlePortChange}
                                        vertical
                                    />
                                </Column>
                            )}
                        </Columns>
                    )}
                    {(canRenderField(FIELDS.TYPE)) && (
                        <AuthTypeContainer>
                            <FormField
                                name="type"
                                required={true}
                                value={submitValues.type}
                                label={<Translate content="computeResource.actionDialog.authType" />}
                            >
                                <TypeContainer>
                                    <SegmentedControl
                                        buttons={authTypes.map(type => ({ value: type.value.toString(), title: type.label }))}
                                        selected={submitValues.type}
                                        onChange={handleAuthTypeChange}
                                        data-cy={COMPUTE_RESOURCE_FORM.AUTH_TYPE}
                                    />
                                </TypeContainer>
                            </FormField>
                            {renderAuthInputs()}
                        </AuthTypeContainer>
                    )}
                    {(canRenderField(FIELDS.LOCATIONS)) && (
                        <FormField
                            name="locations"
                            value={selectedLocations}
                            label={<Translate content="computeResource.actionDialog.locations" />}
                        >
                            {({ getId }) => (
                                <AsyncSelectInput
                                    menuPosition="fixed"
                                    value={selectedLocations}
                                    isMulti={true}
                                    loadOptions={loadLocationOptions}
                                    onChange={handleLocationsChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    inputId={getId()}
                                />
                            )}
                        </FormField>
                    )}
                    {(canRenderField(FIELDS.IP_BLOCKS)) && (
                        <FormField
                            name="ip_blocks"
                            value={selectedIpBlocks}
                            label={<Translate content="computeResource.actionDialog.ipBlocks" />}
                        >
                            {({ getId }) => (
                                <AsyncSelectInput
                                    menuPosition="fixed"
                                    value={selectedIpBlocks}
                                    isMulti={true}
                                    loadOptions={loadIpBlockOptions}
                                    onChange={handleIpBlocksChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    inputId={getId()}
                                />
                            )}
                        </FormField>
                    )}
                    {(canRenderField(FIELDS.LICENSE_TYPE)) && licenseModel === LicenseModel.SOLUS_VM_V1 && (
                        <FormFieldSelect
                            name="license_type"
                            value={submitValues.license_type}
                            label={<Translate content="computeResource.actionDialog.licenseType.label" />}
                            onChange={handleLicenseTypeChange}
                            size={SIZE.FILL}
                            fullDescription={<Translate content="computeResource.actionDialog.licenseType.description" params={{
                                micro: 2,
                                mini: 5,
                                standard: (<Translate content="computeResource.actionDialog.licenseType.standardLimit" />),
                            }}/>}
                        >
                            {Object.values(LicenseType).map(value => (
                                <SelectOption key={value} value={value}>{upFirstChar(value)}</SelectOption>
                            ))}
                        </FormFieldSelect>
                    )}
                </Section>
            </Form>
            {item.id > 0 &&
                <ProgressStates
                    isOpen={true}
                    steps={installStepsEl}
                />
            }
        </>
    );
});

const mapStateToProps = (state: RootState) => ({
    licenseModel: state.settings.license.model,
    userEmail: state.auth.user.email,
    formErrors: nestStringProperties(state),
    isItemSelected: isComputeResourceSelected(state),
    installSteps: getComputeResourceInstallSteps(state, state.computeResource.item.id),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    bakeToast: bindActionCreators(toasterActions.bakeForegroundToast, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
    forwardRef: true,
})(ComputeResourceForm);
