// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { StyledDialog } from 'common/components/Dialog/Styles';
import { CheckBox } from 'common/components/CheckBox/CheckBox';

export const OnboardingModal = styled(StyledDialog)`
    --onboarding-dialog-height: 650px;
    
    .pul-dialog__content {
        height: var(--onboarding-dialog-height);
        max-height: var(--onboarding-dialog-height);
    }
`;

export const OnboardingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const OnboardingSidebarContainer = styled.aside`
    --onboarding-sidebar-padding: 24px;
    
    background-color: #f0faff;
    width: 252px;
    padding: var(--onboarding-sidebar-padding);
    display: flex;
    flex-direction: column;
`;

export const OnboardingSidebarHeader = styled.h2`
    font-size: 24px;
    margin-bottom: 16px;
    text-decoration: underline #28AADE;
`;

export const OnboardingSidebarButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
`;

export const OnboardingStepperStepWrapper = styled.li<{ $variant: 'active' | 'completed' | 'disabled' }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    font-weight: 600;
    color: ${props => props.$variant === 'active' ? 'black' : '#737373'};
    margin-bottom: 16px;
`;

export const OnboardingStepperCheckbox = styled(CheckBox)`
    * {
        pointer-events: none;
    }
    > input[type=checkbox] {
        & + label {
            cursor: default;

            > svg {
                margin-right: 0;
            }
        }
        
        &:disabled + label {
            opacity: 0.5;
        }
    }
`;

export const OnboardingStepperContainer = styled.div`
    position: sticky;
    top: var(--onboarding-sidebar-padding);
`;

export const OnboardingContentContainer = styled.div`
    --onboarding-content-margin: 24px;
    
    width: 600px;
    margin: var(--onboarding-content-margin);
    display: flex;
    flex-direction: column;
    min-height: calc(var(--onboarding-dialog-height) - 2 * var(--onboarding-content-margin));
`;

export const OnboardingHeader = styled.h2`
    font-size: 32px;
    margin-bottom: 24px;
`;

export const OnboardingLead = styled.p`
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
`;

export const OnboardingContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
`;