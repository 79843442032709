// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    StyledSegmentedControl,
    SegmentedControlWithConfirmationContainer,
} from 'common/components/SegmentedControl/Styles';
import { ISegmentedControl } from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { SIZE } from 'common/constants';

export interface ISegmentedControlProps<T> extends Omit<ISegmentedControl<T>, 'ref'> {
    withConfirmation?: boolean;
    confirmationPopoverTranslations?: {
        title: React.ReactNode;
        button: React.ReactNode;
        text?: React.ReactNode;
    };
    size?: SIZE;
}

export const SegmentedControl = <T,>({
    withConfirmation = false,
    confirmationPopoverTranslations,
    buttons,
    selected,
    onChange,
    size,
    ...rest
}: ISegmentedControlProps<T>) => {
    if (!withConfirmation) {
        return (
            <StyledSegmentedControl
                buttons={buttons}
                selected={selected}
                ghost={true}
                onChange={onChange as (value: unknown) => void | undefined}
                $size={size}
                {...rest}
            />
        );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const renderedButtons = React.useMemo(() => buttons?.map(({
        title,
        value,
        tooltip,
        disabled = false,
    }) => {
        const handleChange = () => {
            onChange?.(value);
        };

        return (
            <ButtonWithConfirmation
                handleConfirm={handleChange}
                translations={{
                    title: confirmationPopoverTranslations?.title,
                    button: confirmationPopoverTranslations?.button,
                    text: confirmationPopoverTranslations?.text,
                    tooltip,
                }}
                disabled={disabled}
                key={(value as unknown) as string}
                confirmationButtonText={title}
                buttonClassName={selected === value ? 'pul-button--selected' : undefined}
            />
        );
    }), [confirmationPopoverTranslations, buttons, selected, onChange]);

    return (
        <SegmentedControlWithConfirmationContainer>
            {renderedButtons}
        </SegmentedControlWithConfirmationContainer>
    );
};
