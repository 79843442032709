// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as actions from 'common/modules/app/meta/actions';
import { IMeta } from 'common/api/resources/Meta';
import { OnboardingStep } from 'common/api/resources/Onboarding';

export type MetaAction = ActionType<typeof actions>;
export type MetaState = Readonly<IMeta>;

export const initialMetaState: IMeta = {
    buildDate: '',
    buildNumber: '',
    commit: '',
    isLicenseActive: true,
    version: '',
    defaultLanguage: null,
    cluster: '',
    clusterOnboarding: OnboardingStep.SKIP,
};

export default (state: MetaState = initialMetaState, action: MetaAction) => {
    switch (action.type) {
    case getType(actions.setLicenseIsActive):
        return { ...state, isLicenseActive: action.payload };
    case getType(actions.setMeta):
        return action.payload;
    default:
        return state;
    }
};
