// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    OnboardingLead,
    OnboardingSidebarButtons,
    OnboardingSidebarHeader,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import { Translate } from '@plesk/ui-library';

export interface IOnboardingStepWelcomeProps {
    onStart: () => void;
}

export const OnboardingStepWelcome: React.FC<IOnboardingStepWelcomeProps> = ({
    onStart,
}) => {
    const { skip } = useOnboarding();

    return (
        <OnboardingStep
            sidebar={(
                <>
                    <OnboardingSidebarHeader>
                        <Translate content={'onboarding.clusterSetup.steps.welcome.header'}/>
                    </OnboardingSidebarHeader>

                    <OnboardingLead>
                        <Translate content={'onboarding.clusterSetup.steps.welcome.lead'}/>
                    </OnboardingLead>
                    <p>
                        <Translate content={'onboarding.clusterSetup.steps.welcome.paragraphs.0'}/>
                    </p>
                    <p>
                        <Translate content={'onboarding.clusterSetup.steps.welcome.paragraphs.1'}/>
                    </p>
                    <OnboardingSidebarButtons>
                        <Button
                            size={SIZE.LG}
                            intent={'primary'}
                            onClick={onStart}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.welcome.buttons.start'}/>
                        </Button>
                        <Button
                            size={SIZE.LG}
                            intent={'secondary'}
                            onClick={skip}
                        >
                            <Translate content={'onboarding.clusterSetup.steps.welcome.buttons.skip'}/>
                        </Button>
                    </OnboardingSidebarButtons>
                </>
            )}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}>
                <img
                    src={'/images/onboarding/cluster-setup/welcome.png'}
                    alt={'SolusVM Logo'}
                />
            </div>
        </OnboardingStep>
    );
};