// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import {
    ISettingsRequest,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import {
    FormFieldCheckbox,
    FormFieldText,
    FormInstanceHandles,
    Section,
    Translate,
} from '@plesk/ui-library';
import { SIZE } from 'common/constants';
import { RootState } from 'admin/core/store';

interface IMailSettingsProps {
    fields?: FIELDS[];
    showTitle?: boolean;
    submitButton?: boolean;
    hideIfDisabled?: boolean;
    extraFields?: React.ReactNode;
    onSubmitted?: () => void;
}

export type MailSettingsProps = IMailSettingsProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export enum FIELDS {
    ENABLED = 'is_enabled',
    HOST = 'host',
    PORT = 'port',
    USERNAME = 'username',
    PASSWORD = 'password',
    FROM_EMAIL = 'from_email',
    FROM_NAME = 'from_name',
    ENCRYPTION = 'encryption',
    SEND_TEST_MAIL = 'send_test_mail',
    TEST_MAIL = 'test_mail'
}

export const MailSettings = React.forwardRef<FormInstanceHandles, MailSettingsProps>(({
    settingsActions: { saveSettings },
    formErrorsActions: { setFormErrors },
    fields,
    showTitle = true,
    submitButton = true,
    hideIfDisabled = false,
    settings: { mail: { is_enabled } },
    extraFields,
    onSubmitted,
}, ref) => {
    const noFieldsFilter = fields === undefined;

    const canRenderField = (requiredField: FIELDS) => noFieldsFilter || fields.includes(requiredField);

    const handleSubmit = async (values: ISettingsResponse) => {
        const formErrors = validate<ISettingsResponse>(values, {
            'mail.host': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.port': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.password': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.username': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.from_email': requiredRule(<Translate content="validate.fieldRequired" />),
            'mail.from_name': requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(formErrors).length) {
            setFormErrors(formErrors);
            return;
        }

        const data: ISettingsRequest = {
            mail: values.mail,
            features: values.features,
        };

        await saveSettings(data);

        onSubmitted && onSubmitted();
    };

    return (
        <SettingsWrapper
            ref={ref}
            title={showTitle ? 'settings.titles.mail' : undefined}
            onSubmit={handleSubmit}
            submitButton={submitButton}
        >
            <Section>
                {canRenderField(FIELDS.ENABLED) && (
                    <FormFieldCheckbox
                        name="mail[is_enabled]"
                        label={<Translate content="settings.mail.enabled"/>}
                    />
                )}
                { (!hideIfDisabled || is_enabled) && (
                    <>
                        {canRenderField(FIELDS.HOST) && (
                            <FormFieldText
                                size={SIZE.XL}
                                name="mail[host]"
                                label={<Translate content="settings.mail.host" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.PORT) && (
                            <FormFieldText
                                size={SIZE.SM}
                                name="mail[port]"
                                label={<Translate content="settings.mail.port" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.USERNAME) && (
                            <FormFieldText
                                size={SIZE.XL}
                                name="mail[username]"
                                label={<Translate content="settings.mail.username" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.PASSWORD) && (
                            <FormFieldPassword
                                size={SIZE.XL}
                                name="mail[password]"
                                label={<Translate content="settings.mail.password" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.FROM_EMAIL) && (
                            <FormFieldText
                                size={SIZE.XL}
                                name="mail[from_email]"
                                label={<Translate content="settings.mail.from.email" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.FROM_NAME) && (
                            <FormFieldText
                                size={SIZE.XL}
                                name="mail[from_name]"
                                label={<Translate content="settings.mail.from.name" />}
                                required={true}
                            />
                        )}
                        {canRenderField(FIELDS.ENCRYPTION) && (
                            <FormFieldCheckbox
                                name="mail[encryption]"
                                label={<Translate content="settings.mail.encryption"/>}
                            />
                        )}
                        {canRenderField(FIELDS.SEND_TEST_MAIL) && (
                            <FormFieldCheckbox
                                name="mail[send_test_mail]"
                                label={<Translate content="settings.mail.testMail"/>}
                            />
                        )}
                        {canRenderField(FIELDS.TEST_MAIL) && (
                            <FormFieldText
                                placeholder="Enter test email here..."
                                name="mail[test_mail]"
                            />
                        )}
                        {extraFields}
                    </>
                )}
            </Section>
        </SettingsWrapper>
    );
});

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(MailSettings);
