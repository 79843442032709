// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import {
    IOnboarding,
    OnboardingStep,
    IOnboardingStepState,
} from 'common/api/resources/Onboarding';
import * as actions from 'admin/onboarding/actions';
import {
    ActionType,
    getType,
} from 'typesafe-actions';

export const initialOnboardingState: IOnboarding = {
    step: OnboardingStep.SKIP,
    all_steps: Object.fromEntries(Object.values(OnboardingStep).map((key) => ([key, {
        submitted: false,
        prerequisites: false,
    } as IOnboardingStepState]))) as Record<OnboardingStep, IOnboardingStepState>,
    compute_resource_id: null,
};

export type OnboardingAction = ActionType<typeof actions>;

export default (state: IOnboarding = initialOnboardingState, action: OnboardingAction) => {
    switch (action.type) {
    case getType(actions.setOnboardingClusterSetup):
        return { ...state, ...action.payload };
    default:
        return state;
    }
};