// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Alert,
    Form,
    FormField,
    FormFieldSelect,
    FormInstanceHandles,
    ISegmentedControlButton,
    Section,
    Translate,
} from '@plesk/ui-library';
import * as computeResourceActions from 'admin/computeResource/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { validate } from 'common/validator';
import isEmpty from 'validator/lib/isEmpty';
import {
    Loader,
    Tooltip,
} from 'common/components';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import ConfirmationDialog from 'common/components/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'admin/common/components/Button/Button';
import NetworkFormError from 'common/containers/NetworkFormError/NetworkFormError';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { NetworkType } from 'common/api/resources/ComputeResource';
import { humanizeNetworkType } from 'admin/computeResource/constants';

interface IComputeResourceConfigureNetworkProps {
    onConfigured: () => void;
    submitButton?: boolean;
    hideUnavailable?: boolean;
}

export type ComputeResourceConfigureNetworkProps =
    IComputeResourceConfigureNetworkProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceConfigureNetwork = React.forwardRef<FormInstanceHandles, ComputeResourceConfigureNetworkProps>(({
    onConfigured,
    submitButton = true,
    hideUnavailable = false,
    selectedItem,
    networks,
    errors,
    loadingFlags: { isNetworksLoading },
    computeResourceActions: {
        setUpNetwork,
        getComputeResourceNetworks,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}, ref) => {
    const [dialog, setDialog] = React.useState<React.ReactNode>(null);
    const [networkIndex, setNetworkIndex] = React.useState('');
    const [type, setType] = React.useState<NetworkType>(NetworkType.BRIDGED);
    const [networkTypeOptions, setNetworkTypeOptions] = React.useState<Array<ISegmentedControlButton<NetworkType>>>([]);

    React.useEffect(() => {
        getComputeResourceNetworks(selectedItem.id);
    }, [selectedItem.id, getComputeResourceNetworks]);

    React.useEffect(() => {
        setNetworkTypeOptions(
            Object.values(NetworkType)
                .map((networkType) => ({
                    value: networkType,
                    title: humanizeNetworkType(networkType),
                    disabled: networkType === NetworkType.VZ_ROUTED || selectedItem.vms_count > 0,
                    tooltip: networkType === NetworkType.VZ_ROUTED ? (
                        <Translate content="computeResource.settings.form.network.vzRoutedIsNotAllowed"/>
                    ) : null,
                }))
                .filter((option) => !option.disabled || !hideUnavailable)
        );
    }, [selectedItem.vms_count, hideUnavailable]);

    const handleFieldChange = React.useCallback((field: string, value: string) => {
        clearFormErrors();

        if (field === 'network_index') {
            setNetworkIndex(value);
        } else if (field === 'type') {
            setType(value as NetworkType);
        }
    }, [clearFormErrors, setNetworkIndex, setType]);

    const handleCloseDialog = () => {
        setDialog(null);
    };

    const handleCreate = React.useCallback(async () => {
        const validationErrors = validate({ network_index: networkIndex }, {
            network_index: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired"/>,
                comparison: true,
            },
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        const network = networks[parseInt(networkIndex, 10)];

        const confirm = async () => {
            try {
                handleCloseDialog();
                await setUpNetwork(selectedItem.id, {
                    id: network.id,
                    type,
                });
                onConfigured();
            } catch (e) {
                throw e;
            }
        };

        if (network.type !== 'openvswitch') {
            setDialog(
                <ConfirmationDialog
                    isOpen={true}
                    isLoading={!!selectedItem.is_configuring_network}
                    handleClick={confirm}
                    onClose={handleCloseDialog}
                    title={<Translate content="computeResource.actionDialog.network.confirmTitle"/>}
                    innerText={<Alert intent={INTENT_TYPE.DANGER}><Translate
                        content="computeResource.actionDialog.network.confirmText"/></Alert>}
                />
            );
        } else {
            confirm();
        }
    }, [networkIndex, networks, setFormErrors, setUpNetwork, selectedItem, type, onConfigured]);

    return (
        <>
            {dialog}
            <Loader isLoading={isNetworksLoading} center={false}>
                <Form
                    ref={ref}
                    id="configureNetworkForm"
                    footerClassName="hidden"
                    onSubmit={handleCreate}
                    onFieldChange={handleFieldChange}
                    errors={errors}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                >
                    <Section>
                        <FormFieldSelect
                            name="network_index"
                            label={<Translate content="computeResource.actionDialog.network.networks"/>}
                            size={SIZE.FILL}
                            required={true}
                        >
                            {networks.map((network, index) => (
                                <option key={index} value={index.toString()}>
                                    {`${network.name}:${network.ip}/${network.mask_size}(${network.type})`}
                                </option>
                            ))}
                        </FormFieldSelect>
                        <FormField
                            fullDescription={
                                <Translate
                                    content="computeResource.settings.form.network.fullDescription"
                                    params={{
                                        link: (
                                            <a
                                                href="https://docs.solusvm.com/v2/administrator-guide/Choosing%2Ba%2BCompute%2BResource%2BNetwork%2BType.html"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Translate
                                                    content="computeResource.settings.form.network.fullDescriptionLink"/>
                                            </a>
                                        ),
                                    }}
                                />
                            }
                            name="type"
                            required={true}
                            label={<Translate content="computeResource.settings.form.network.type"/>}
                        >
                            <Tooltip
                                shown={selectedItem.vms_count > 0}
                                title={<Translate content="computeResource.settings.form.network.description"/>}
                            >
                                <SegmentedControl
                                    buttons={networkTypeOptions}
                                    selected={type}
                                    onChange={(value) => handleFieldChange('type', value)}
                                />
                            </Tooltip>
                        </FormField>
                        <NetworkFormError/>
                    </Section>
                </Form>
                {submitButton && (
                    <Button
                        intent={INTENT_TYPE.PRIMARY}
                        type="submit"
                        fill={true}
                        size="lg"
                        form="configureNetworkForm"
                        state={!!selectedItem.is_configuring_network ? 'loading' : undefined}
                    >
                        <Translate content="computeResource.actionDialog.network.configure"/>
                    </Button>
                )}
            </Loader>
        </>
    );
});

const mapStateToProps = (state: RootState) => ({
    errors: state.app.formErrors,
    networks: state.computeResource.networks,
    selectedItem: state.computeResource.item,
    loadingFlags: {
        isNetworksLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_NETWORKS),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
    forwardRef: true,
})(ComputeResourceConfigureNetwork);
