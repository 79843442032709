// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import { OnboardingStep } from 'common/api/resources/Onboarding';
import { OnboardingStepWelcome } from 'admin/onboarding/components/OnboardingStepWelcome';
import OnboardingStepActivateLicense from 'admin/onboarding/components/OnboardingStepActivateLicense';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import OnboardingStepAddComputeResource from 'admin/onboarding/components/OnboardingStepAddComputeResource';
import OnboardingStepConnectComputeResource from 'admin/onboarding/components/OnboardingStepConnectComputeResource';
import OnboardingStepAddIpBlock from 'admin/onboarding/components/OnboardingStepAddIpBlock';
import { setIpBlockItem } from 'admin/ipBlock/actions';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import OnboardingStepAddVmPlan from 'admin/onboarding/components/OnboardingStepAddVmPlan';
import OnboardingStepMailSettings from 'admin/onboarding/components/OnboardingStepMailSettings';
import { OnboardingStepCreateFirstVm } from 'admin/onboarding/components/OnboardingStepCreateFirstVm';

export interface IOnboardingContentProps {}

export type OnboardingContentProps = IOnboardingContentProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const OnboardingContent: React.FC<OnboardingContentProps> = ({
    computeResource,
    locations,
}) => {
    const { step: currentStep, updateOnboardingState, close } = useOnboarding();

    const computeResourceItem = React.useMemo<IComputeResourceResponse>(() => ({
        ...computeResource,
        locations: locations.data,
    }), [computeResource, locations.data]);

    switch (currentStep) {
    case OnboardingStep.WELCOME:
        return (
            <OnboardingStepWelcome
                onStart={async () => {
                    await updateOnboardingState(OnboardingStep.ACTIVATE_LICENSE);
                }}
            />
        );
    case OnboardingStep.ACTIVATE_LICENSE:
        return (
            <OnboardingStepActivateLicense
                onNext={() => {
                    updateOnboardingState(OnboardingStep.ADD_COMPUTE_RESOURCE);
                }}
            />
        );
    case OnboardingStep.ADD_COMPUTE_RESOURCE:
        return (
            <OnboardingStepAddComputeResource
                item={computeResourceItem}
                onNext={(computeResourceId) => {
                    updateOnboardingState(OnboardingStep.CONNECT_COMPUTE_RESOURCE, computeResourceId);
                }}
            />
        );
    case OnboardingStep.CONNECT_COMPUTE_RESOURCE:
        return (
            <OnboardingStepConnectComputeResource
                item={computeResourceItem}
                onNext={() => {
                    updateOnboardingState(OnboardingStep.ADD_IP_BLOCK);
                }}
            />
        );
    case OnboardingStep.ADD_IP_BLOCK:
        return (
            <OnboardingStepAddIpBlock
                onNext={() => {
                    updateOnboardingState(OnboardingStep.ADD_VM_PLAN);
                }}
            />
        );
    case OnboardingStep.ADD_VM_PLAN:
        return (
            <OnboardingStepAddVmPlan
                onNext={() => {
                    updateOnboardingState(OnboardingStep.MAIL_SETTINGS);
                }}
            />
        );
    case OnboardingStep.MAIL_SETTINGS:
        return (
            <OnboardingStepMailSettings
                onNext={() => {
                    updateOnboardingState(OnboardingStep.CREATE_FIRST_VM);
                }}
            />
        );
    case OnboardingStep.CREATE_FIRST_VM:
        return (
            <OnboardingStepCreateFirstVm
                onNext={async () => {
                    await updateOnboardingState(OnboardingStep.DONE);
                    close();
                }}
            />
        );
    case OnboardingStep.DONE:
    case OnboardingStep.SKIP:
        return null;
    }

    throw new Error(`Unknown onboarding step: ${currentStep}`);
};

const mapStateToProps = (state: RootState) => ({
    locations: state.location.list,
    computeResource: state.computeResource.item,
    onboarding: state.onboarding,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setIpBlock: bindActionCreators(setIpBlockItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContent);