// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { OnboardingStep } from 'admin/onboarding/components/OnboardingStep';
import { OnboardingStepper } from 'admin/onboarding/components/OnboardingStepper';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';
import {
    OnboardingHeader,
    OnboardingLead,
    OnboardingContentFooter,
} from 'admin/onboarding/components/Styled';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import {
    FormInstanceHandles,
    Translate,
} from '@plesk/ui-library';
import PlanForm, {
    FIELDS,
    PlanRequest,
} from 'common/components/plan/PlanForm';
import { initialKVMPlanRequest } from 'common/modules/plan/reducer';
import { IPlanCreateRequest } from 'common/api/resources/Plan';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as planActions from 'common/modules/plan/actions';
import { connect } from 'react-redux';
import { pathTo } from 'common/helpers/core';
import { Link } from 'react-router-dom';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export interface IOnboardingStepAddVmPlanProps {
    onNext: () => void;
}

export type OnboardingStepAddVmPlanProps = IOnboardingStepAddVmPlanProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const OnboardingStepAddVmPlan: React.FC<OnboardingStepAddVmPlanProps> = ({
    onNext,
    createPlan,
    isItemSaving,
}) => {
    const formRef = React.useRef<FormInstanceHandles>(null);
    const { close } = useOnboarding();

    const handleSubmit = async (request: PlanRequest): Promise<void> => {
        await createPlan(request as IPlanCreateRequest);
        onNext();
    };

    return (
        <OnboardingStep
            sidebar={(<OnboardingStepper/>)}
        >
            <OnboardingHeader>
                <Translate content={'onboarding.clusterSetup.steps.addVmPlan.header'}/>
            </OnboardingHeader>
            <OnboardingLead>
                <Translate content={'onboarding.clusterSetup.steps.addVmPlan.lead'}/>
            </OnboardingLead>
            <p>
                <Translate content={'onboarding.clusterSetup.steps.addVmPlan.paragraphs.0'}/>
            </p>

            <PlanForm
                ref={formRef}
                initialState={{
                    ...initialKVMPlanRequest,
                    available_locations: [],
                    available_os_image_versions: [],
                    available_applications: [],
                }}
                onSubmit={handleSubmit}
                fields={[
                    FIELDS.NAME,
                    FIELDS.VCPU,
                    FIELDS.DISK,
                    FIELDS.RAM,
                    FIELDS.AVAILABLE_LOCATIONS,
                    FIELDS.AVAILABLE_OS_IMAGE_VERSIONS,
                    FIELDS.AVAILABLE_APPLICATIONS,
                    FIELDS.IMAGE_PRESET_IDS,
                ]}
                submitButton={false}
            />

            <p>
                <Translate
                    content={'onboarding.clusterSetup.steps.addVmPlan.hints.advancedConfiguration'}
                    params={{
                        vmPlansLink: (
                            <Link
                                to={pathTo('plans')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Translate content={'onboarding.clusterSetup.steps.addVmPlan.hints.vmPlansLinkTitle'} />
                            </Link>
                        ),
                    }}
                />
            </p>

            <OnboardingContentFooter>
                <Button
                    size={SIZE.LG}
                    intent={'secondary'}
                    onClick={close}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addVmPlan.buttons.close'}/>
                </Button>
                <Button
                    size={SIZE.LG}
                    intent={'primary'}
                    onClick={() => formRef.current?.submit()}
                    isLoading={isItemSaving}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addVmPlan.buttons.continue'}/>
                </Button>
            </OnboardingContentFooter>
        </OnboardingStep>
    );
};

const mapStateToProps = (state: RootState) => ({
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_PLAN_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createPlan: bindActionCreators(planActions.createPlan, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStepAddVmPlan);